// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoData_container__3Tg-I {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #868e96;\n  margin-top: 64px;\n}\n\n@media screen and (max-width: 425px) {\n  .NoData_container__3Tg-I {\n    flex-direction: column;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/common/Nodata/NoData.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;AACF","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #868e96;\n  margin-top: 64px;\n}\n\n@media screen and (max-width: 425px) {\n  .container {\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NoData_container__3Tg-I"
};
export default ___CSS_LOADER_EXPORT___;
