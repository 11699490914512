import React, { FC, useEffect, useState } from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { IJournalActions, IJournalActionsChildren, IRaport } from "../../../store/reports/types";
import { Dialog } from "@material-ui/core";
import { ModalHeader } from "../../common/Header/ModalHeader/ModalHeader";
import { formatIsoToDate, formatIsoToTime } from "../../helpers/utils";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { Table } from "antd";
import { NoData } from "../../common/Nodata/NoData";

const s = require('./style.module.scss')

interface IInfoModal extends IModal {
    i: any | null
    arrData : any
}

export const InfoModalActions: FC<IInfoModal> = ({
    closeHandler,
    i,
    open,
    title,
    arrData
}) => {
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)

    const item = arrData.find((el: { id: any; })  => el.id === i?.key || i?.id)

    const time = item?.time_work
    useEffect(() => {
        console.log(i?.childrens?.length)
    }, [i])

    let utcDate;
    if (time) {
        utcDate = new Date(time);
    } else {
        utcDate = new Date(); 
    }

    var localDate = new Date(utcDate.getTime());
    
    const id = item?.id

    interface DataType {
            "id": number,
            "updated_at": string,
            "name": string,
            "address": string,
            "operator": string,
            "action": string,
            "pco": string
    }

    const columns = [
        {
          title: 'Дата',
          dataIndex: 'updated_at',
          key: 'updated_at',
        },
        {
          title: 'Оператор',
          dataIndex: 'operator',
          key: 'operator',
        },
        {
          title: 'Событие',
          dataIndex: 'action',
          key: 'action',
        },
      ];
    
      const data: DataType[] = i?.childrens?.map((element: any) => {
        return (
            {
                updated_at: element?.updated_at && formatIsoToDate(element?.updated_at) + ` ` + formatIsoToTime(element?.updated_at),
                operator: element?.operator,
                action: element?.action,
            }
        )
    })

    return <Dialog open={open}>
        <ModalHeader id={i?.id} title={title} closeHandler={closeHandler} />
        <div className={s.body}>
            <div className={s.body__list}>
            {i?.childrens?.length === 0 ? <div style={{padding:'0 0 48px 0'}}><NoData /></div> : 
                                <Table
                                size='large'
                                rowClassName={s.tableRow}
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                // pagination={{
                                //     position: ['topLeft', 'bottomLeft'],
                                //     pageSizeOptions: [25, 50, 75, 100],
                                //     defaultPageSize: pageSize,
                                //     onChange: (page, pageSize) => {
                                //         setPage(page)
                                //         setPageSize(pageSize)
                                //     },
                                //     defaultCurrent : page,
                                //     total : i?.childrens?.length,
                                //     current : page
                                // }}
                            />
            }
            </div>
        </div>
    </Dialog>
}