
import formatStringByPattern from "format-string-by-pattern";

export interface TEnumItem {
    id: number | string
    name: string
    mnemo?: string
    value?: string
    isCustom?: boolean // нужно для системы, чтобы сильно не переделывать, бахнул в этот общий тип данное поле
    number?: string
    address?: string
    group?: number
    url?: string
    is_inherited?: boolean
    type_id?: number
    order?: number,
    size?: number
}

export interface IAlarmKindItem {
    id: number
    name: string
}

export interface IFilterButtonItem {
    name: string
    icon: boolean
    width: number
    page: 'employees' | 'myApplications' | 'history' | 'icon'
    flag?: string
}

export const FilterButtons: IFilterButtonItem[] = [
    {
        name: 'Новые',
        icon: false,
        width: 99,
        page: 'myApplications',
        flag: 'new'
    },
    {
        name: 'Просроченные',
        icon: false,
        width: 154,
        page: 'myApplications',
        flag: 'dismissed'
    },
    {
        name: 'В работе',
        icon: false,
        width: 130,
        page: 'myApplications',
        flag: 'inWork'
    },
    {
        name: 'Назначенные',
        icon: false,
        width: 144,
        page: 'myApplications',
        flag: 'designated'
    },
    {
        name: 'Наблюдаемые',
        icon: false,
        width: 149,
        page: 'myApplications',
        flag: 'observed'
    },
    {
        name: 'Добавить в группу',
        icon: false,
        width: 180,
        page: 'employees'
    }
    /*{
        name: 'Добавить сотрудника',
        icon: false,
        width: 180,
        page: 'employees'
    },
    {
        name: 'Экспорт в XSL',
        icon: false,
        width: 134,
        page: 'history'
    },*//*
    {
        name: '',
        icon: true,
        width: 55,
        page: 'icon'
    }*/
]


const addZeroHandler = (_time: number) => {
    const time = _time.toString()
    if (time.length === 1) {
        return `0${time}`
    }
    return time
}

export function createDate(date?: string): Date | null {
    if (!date) return null
    let timestamp = Date.parse(date);
    if (!isNaN(timestamp)) {
        return new Date(timestamp);
    } else {
        return null
    }
}

export function parseDate(date: Date) {
    let dd: string | number = date.getDate();
    if (dd < 10) dd = '0' + dd;
    let mm: string | number = date.getMonth() + 1; // месяц 1-12
    if (mm < 10) mm = '0' + mm;
    let yy: string | number = date.getFullYear();
    if (yy < 10) yy = '0' + yy;
    return `${dd}.${mm}.${yy}`;
}

export function formatStringToDateStringFormat(date: string) {
    return date.slice(3, 5) + '.' + date.slice(0, 2) + '.' + date.slice(6, 10)
}

export function formatIsoToDate(_date: string) {
    return _date.slice(8, 10) + '.' + _date.slice(5, 7) + '.' + _date.slice(0, 4)
}

export function formatIsoToTime(_date: string) {
    const hours = _date.slice(11, 13)
    const minutes = _date.slice(14, 16)
    const seconds = _date.slice(17, 19)
    return addZeroHandler(+hours) + ':' + addZeroHandler(+minutes) + ':' + addZeroHandler(+seconds)
}

export const getCurrentTime = () => {
    const date = new Date();
    return `${addZeroHandler(date.getHours())}:${addZeroHandler(date.getMinutes())}`
}



export const filterNumbersFromString = (string: string) => {
    return string?.replace(/[^\d]/g, '')
}


export const formatPhoneByPattern = (value: string) => {
    if (!!value?.includes("*")) {
        return formatStringByPattern('+7 (999) 999 9', value) + "* **"
    }
    const _phone = value?.length === 1 && value[0] !== '7' ? '7' : value
    return formatStringByPattern('+9 (999) 999 9999', filterNumbersFromString(_phone))
}