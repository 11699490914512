import {AUTH_ACTIONS, TAuthReducer} from "./types";

const initialState: TAuthReducer = {
    myInfo: null,
    loading: false
}

export const authReducer = (state = initialState, action: AUTH_ACTIONS) => {
    switch (action.type) {
        case "SET_MY_INFO": {
            return {
                ...state,
                myInfo: action.payload
            }
        }
        case "SET_MY_INFO_LOADING": {
            return {
                ...state,
                loading: action.payload
            }
        }
        default:
            return state
    }
}