import React, { useEffect } from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';

interface IMobileRaportAlertProps {
    items : any
    loading: any
    // onItemClick : (element : any) => void
  }

export const MobileRaportAlert: React.FC<IMobileRaportAlertProps> = ({items, loading}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

useEffect(() => {
    
})

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
                <Card style={{margin: '15px 0 15px 0'}} key={element.id} title={titleCard(element.operatorSent)} size='default'>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Дата :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.date === 'string' ? element?.date : ''}>
                                    {element?.date ? element?.date : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ПЦО :</div>
                            <div>
                                {element?.pco ? element?.pco : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Пульт :</div>
                            <div>
                                {element?.pult ? element?.pult : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Время сработки :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.timeWork === 'string' ? element?.timeWork : ''}>
                                    {element?.timeWork ? element?.timeWork.split('').length >= 20 ? `${element?.timeWork.substr(0, 20)}...` : element?.timeWork : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Время вызова ГБР :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.timeCallGbr === 'string' ? element?.timeCallGbr : ''}>
                                    {element?.timeCallGbr ? element?.timeCallGbr.split('').length >= 20 ? `${element?.timeCallGbr.substr(0, 20)}...` : element?.timeCallGbr : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Оператор принял :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.operatorTaked === 'string' ? element?.operatorTaked : ''}>
                                    {element?.operatorTaked ? element?.operatorTaked.split('').length >= 15 ? `${element?.operatorTaked.substr(0, 15)}...` : element?.operatorTaked : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Оператор выслал :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.operatorSent === 'string' ? element?.operatorSent : ''}>
                                    {element?.operatorSent ? element?.operatorSent.split('').length >= 20 ? `${element?.operatorSent.substr(0, 20)}...` : element?.operatorSent : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Оператор завершил :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.operatorClosed === 'string' ? element?.operatorClosed : ''}>
                                    {element?.operatorClosed ? element?.operatorClosed.split('').length >= 15 ? `${element?.operatorClosed.substr(0, 15)}...` : element?.operatorClosed : '-'}
                                </Tooltip>
                            </div>
                        </div>

                </Card>
            )}
            />
    </Space>
  )
}