// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".classes_errorText__1GxWe {\n  color: #DF0E0F;\n  line-height: 1;\n  font-size: 10px;\n  width: 100%;\n  text-align: right;\n  z-index: 10000000;\n}", "",{"version":3,"sources":["webpack://src/components/helpers/ErrorText/classes.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;AACF","sourcesContent":[".errorText {\n  color: #DF0E0F;\n  line-height: 1;\n  font-size: 10px;\n  width: 100%;\n  text-align: right;\n  z-index: 10000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorText": "classes_errorText__1GxWe"
};
export default ___CSS_LOADER_EXPORT___;
