import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { IRaport } from "../../../store/reports/types";
import { Dialog, ListItem } from "@material-ui/core";
import { ModalHeader } from "../../common/Header/ModalHeader/ModalHeader";
import { formatIsoToDate, formatIsoToTime } from "../../helpers/utils";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { Button, Checkbox, Input, List, Tooltip } from "antd";
import { ReportsStore } from "../../../store/reports/Reports";
import useDebouncedFunction from "../../helpers/useDebounceFunction";
import { debug } from "console";

const s = require('./style.module.scss')

interface IInfoModal extends IModal {
    flag: string
    territoryInfo: any
    arrData: any
    requestHandler: Function
}

export const Modal: FC<IInfoModal> = ({
    title,
    open,
    closeHandler,
    flag,
    territoryInfo,
    arrData,
    requestHandler
}) => {
    const {
        gbrList,
        gbrListLoading,
        getSettings
    } = ReportsStore
    const [newGbrs, setNewGbrs] = useState([])
    const [territoryName, setTerritoryName] = useState<string | undefined>(undefined)
    const [markedTerritories, setMarkedTerritories] = useState<any[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [searchData, setSearchData] = useState<any>(gbrList)
    const item = arrData?.find((el: { id: any; })  => el.id ===  territoryInfo?.id)


    useEffect(() => {
        if(flag === 'post' || flag === 'delete'){
            setNewGbrs(item?.gbrs?.map((element: any) => {
                markedTerritories.push(element.gbrId)
            }
            ))
            setTerritoryName(item?.territoryName)
        }
    }, [open])

    useEffect(() => {
        setSearchData(gbrList)
    }, [gbrList])
    

    let arr: any[] = []
    const pushInTerritoryArr = (id:number) => {
        const isSelected  = markedTerritories.includes(id)
       if(isSelected){
           arr = markedTerritories.filter((gbrId) => gbrId !== id)
           setMarkedTerritories(arr)
       }else{
           arr = [...markedTerritories, id]
           setMarkedTerritories(arr)
       }
    }   

    const changeTerritoryName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTerritoryName(e.target.value)
    }

    const handleClose = () => {
        setNewGbrs([])
        setMarkedTerritories([])
        setTerritoryName('')
    }

    const handleSave = () => {
    if(flag === 'post'){
        getSettings('post', { 
            id: item?.id,
            territoryName: territoryName,
            territoryGbrs: markedTerritories
        }).then((res) => {
            console.log(res)
            handleClose()
            closeHandler()
            getSettings('get',{

            })
        })
    }else if(flag === 'put'){
        getSettings('put', { 
            territoryName: territoryName,
            territoryGbrs: markedTerritories
        }).then(() => {
            handleClose()
            closeHandler()
            requestHandler()
        })
    }else{
        getSettings('delete', {
            territoryId: item?.id
        }).then(() => {
            handleClose()
            closeHandler()
            requestHandler()
        })
    }
    }

    const changeSearchVal = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }
    const handleFindVal = () => {
        let newList: any = []
        gbrList.map((item: any) => {
            if(item.name.toLowerCase().search(String(searchValue).toLowerCase()) != -1 ){
                newList.push(item)
            }
        setSearchData(newList)
        })
    }

    const { Search } = Input;
  return <Dialog open={open} maxWidth={'md'}>
    <ModalHeader title={flag === "post" ? 
        `Редактирование ${territoryName}`:
        flag === "put" ?
        'Создание новой территории' :
        `Удаление ${territoryName}`
    } closeHandler={() => {closeHandler(); handleClose();}} />
    <>
        {
            flag === "post" || flag === 'put' ?
            <div className={s.post}>
                <div className={s.post__leftSide}>
                    <Input 
                    value={territoryName} 
                    onChange={(e) => changeTerritoryName(e)}
                    />
                    <ListItem className={s.post__leftSide__find} button onClick={() => handleSave()}>
                        Сохранить
                    </ListItem>
                </div>
                <div className={s.post__rightSide}>
                    <div className={s.post__rightSide__search}>
                    <Search placeholder="Поиск по территории..." 
                        value={searchValue} 
                        onSearch={() => handleFindVal()}
                        onChange={(e) => changeSearchVal(e)}
                        allowClear/>
                    </div>
                    <div className={s.post__rightSide__list}>
                    <List
                        dataSource={searchData}
                        renderItem={(el: any) => (
                            <List.Item key={el?.id}>
                                <Checkbox 
                                checked={
                                    markedTerritories.includes(el?.id)
                                }
                                onChange={() => pushInTerritoryArr(el?.id)}
                                />
                                <div>
                                    <Tooltip trigger="hover" placement="topLeft" title={el?.name} zIndex={99999999}>
                                        {el?.name.split('').length >= 25 ? `${el?.name.substr(0, 25)}...` : el?.name}
                                    </Tooltip>
                                </div>
                            </List.Item>
                        )}
                    />
                    </div>
                </div>
            </div>
            :
            <div className={s.delete}>
                <div className={s.delete__title}>Вы точно хотите удалить {territoryName}?</div>
                <ListItem className={s.delete__find} button onClick={() => handleSave()}>
                        Удалить
                </ListItem>
            </div>
        }
    </>
  </Dialog>
}
