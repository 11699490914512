// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tableRow__1vFBn {\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/Dislocation/style.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ","sourcesContent":[".tableRow {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "style_tableRow__1vFBn"
};
export default ___CSS_LOADER_EXPORT___;
