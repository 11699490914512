import {IMyInfo} from "../../../store/loginPage/types";

export type TAuthReducer = {
    myInfo: IMyInfo | null
    loading: boolean
}

export const GET_MY_INFO = 'GET_MY_INFO'
export type GET_MY_INFO_ACTION = {
    type: typeof GET_MY_INFO
    payload: {}
}
export const SET_MY_INFO = 'SET_MY_INFO'
export type SET_MY_INFO_ACTION = {
    type: typeof SET_MY_INFO
    payload: IMyInfo
}
export const SET_MY_INFO_LOADING = 'SET_MY_INFO_LOADING'
export type SET_MY_INFO_LOADING_ACTION = {
    type: typeof SET_MY_INFO_LOADING
    payload: boolean
}


export type AUTH_ACTIONS =
    | SET_MY_INFO_ACTION
    | GET_MY_INFO_ACTION
    | SET_MY_INFO_LOADING_ACTION