import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import pdf from '../../../../icons/pdficon.svg'
import xlsx from '../../../../icons/xlsxIcon.svg'
import { ReportsStore } from "../../../../store/reports/Reports";

const s = require('./ModalHeader.module.scss')

interface IModalHeader {
    title: string
    closeHandler: any
    id?: number | undefined
    [key: string]: any
}

export const ModalHeader: React.FC<IModalHeader> = ({
    title,
    closeHandler,
    id,
    ...props
}) => {

    const {
        getReportXlSX,
    } = ReportsStore

    const onClickGetPdf = () => {
        getReportXlSX({
            "reportName": "SelectedRaportReport",
            "fileExtension": "pdf",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "id": id
                    }
                }
            ]
        })
    }

    const onClickGetXlsx = () => {
        getReportXlSX({
            "reportName": "JournalActionsReport",
            "fileExtension": "xlsx",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "id": id
                    }
                }
            ]
        })
    }

    const href = window.location.href.split('/')[window.location.href.split('/').length - 1]

    return (
        <>
            <div className={clsx(s.rootHeader, props?.mobile && s.rootHeader__mobile)}>
                <div className={clsx(s.headerTitle, props?.mobile && s.headerTitle__mobile)}>{title}</div>
                <div className={s.containerForIcons}>
                    {
                        href === "raports"  &&
                        <img onClick={onClickGetPdf} src={pdf} style={{
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                        }} alt="pdf" />
                    }

                    {
                     href === "journalActions" &&
                     <img onClick={onClickGetXlsx} src={xlsx} style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                    }} alt="pdf" />
                     }

                    <IconButton onClick={closeHandler}
                        style={{ padding: 2 }}
                        className={s.headerCloseIcon}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <div className={s.divider}></div>
        </>
    )
}
