import React, { useEffect } from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';

interface IMobileJournalActionsProps {
    items : any
    loading: any
    // onItemClick : (element : any) => void
  }

export const MobileJournalActions: React.FC<IMobileJournalActionsProps> = ({items, loading}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

useEffect(() => {
    
})

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
                <Card style={{margin: '15px 0 15px 0'}} key={element.id} title={titleCard(element.name)} size='default'>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Дата :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.updated_at === 'string' ? element?.updated_at : ''}>
                                {element?.updated_at ? formatIsoToDate(element?.updated_at) + ` ` + formatIsoToTime(element?.updated_at) : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Пульт :</div>
                            <div>
                                {element?.pult_id ? element?.pult_id : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Название :</div>
                            <div>
                            <Tooltip trigger="hover" placement="topLeft" title={typeof element?.name === 'string' ? element?.name : ''}>
                            {element?.name ? element?.name.split('').length >= 20 ? `${element?.name.substr(0, 20)}...` : element?.name : '-'}
                            </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Адрес :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.timeWork === 'string' ? element?.timeWork : ''}>
                                    {element?.address ? element?.address.split('').length >= 20 ? `${element?.address.substr(0, 20)}...` : element?.address : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Оператор :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.timeCallGbr === 'string' ? element?.timeCallGbr : ''}>
                                    {element?.operator ? element?.operator.split('').length >= 20 ? `${element?.operator.substr(0, 20)}...` : element?.operator : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Событие :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.action === 'string' ? element?.action : ''}>
                                    {element?.action ? element?.action.split('').length >= 20 ? `${element?.action.substr(0, 20)}...` : element?.action : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ПЦО :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.pco === 'string' ? element?.pco : ''}>
                                    {element?.pco ? element?.pco.split('').length >= 20 ? `${element?.pco.substr(0, 20)}...` : element?.pco : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Система :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.panel === 'string' ? element?.panel : ''}>
                                    {element?.panel ? element?.panel.split('').length >= 20 ? `${element?.panel.substr(0, 20)}...` : element?.panel : '-'}
                                </Tooltip>
                            </div>
                        </div>

                </Card>
            )}
            />
    </Space>
  )
}