import React, { FC, useEffect } from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { Dialog } from "@material-ui/core";
import { ModalHeader } from "../../common/Header/ModalHeader/ModalHeader";
import { formatIsoToDate, formatIsoToTime } from "../../helpers/utils";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { IDislocationsItem } from "../../../store/reports/types";

const s = require('./style.module.scss')

interface IInfoModal extends IModal {
    item: IDislocationsItem | null
    arrData : any
}

export const InfoModal: FC<IInfoModal> = ({
    closeHandler,
    item,
    open,
    title,
    arrData
}) => {


    // const item = arrData.find((el: { id: any; })  => el.id === i?.key || i?.id)
    // useEffect(() => {
    //     console.log(i)
    //     console.log(item)
    //     console.log(arrData)
    //     debugger
    // },[])
    useEffect(() => {
        console.log(item)
        debugger
    }, [])


    return <Dialog open={open}>
        <ModalHeader title={title} closeHandler={closeHandler} />
        <div className={s.body}>
            <div className={s.body__list}>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Администратор
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.admin || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Система
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.system || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Время события
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.createdAt ? formatIsoToDate(item?.createdAt) + ' ' + formatIsoToTime(item?.createdAt) : 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Время убытия
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.timeExit ? formatIsoToDate(item?.timeExit) + ' ' + formatIsoToTime(item?.timeExit) : 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Тип дислокации
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.dislocationType || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Тип тревоги
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.alertType || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Наименование
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.name || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Адрес
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.address || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Время сработки
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.timeWork ? formatIsoToDate(item?.timeWork) + ' ' + formatIsoToTime(item?.timeWork) : 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Время вызова
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.timeCall ? formatIsoToDate(item?.timeCall) + ' ' + formatIsoToTime(item?.timeCall) : 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Дата закрытия события
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.dateCloseEvent ? formatIsoToDate(item?.dateCloseEvent) + ' ' + formatIsoToTime(item?.dateCloseEvent) : 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    ГБР
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.gbr || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Причина
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.reason || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Тип события
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.eventType || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Комментарий
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.comment || 'Не указано'}
                    </div>
                </div>

                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Пульт
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.pult || 'Не указано'}
                    </div>
                </div>




                
            </div>
        </div>
    </Dialog>
}