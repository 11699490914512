import { Select, Spin, TreeSelect } from 'antd';
import React, { memo, useState } from 'react'
import { ISimpleSelectProps } from "./types";
import clsx from "clsx";
import { LoadingOutlined } from '@ant-design/icons';
import { ErrorText } from "../ErrorText/Error";
import { AcceptIcon, ArrowIcon, ClearIcon, SimpleSelectDown, SimpleSelectUp } from "../../../icons/icons";
import { Fallback } from '../Fallback/Fallback';

const { Option } = Select;
const classes = require('./selects.module.scss')

export const SimpleSelect: React.FC<ISimpleSelectProps> = memo(({
    width = '100%',
    defaultValue,
    disabled,
    isControlled = false,
    placeholder = 'Выберите',
    items,
    onChange,
    showSearch = false,
    size = 'lg',
    multiple,
    mode,
    onSearch,
    loading,
    className,
    ...props
}) => {
    const { error, invalid } = props;
    const classNames = clsx(
        classes.simple_select,
        (invalid || error) && classes.invalid,
        size === 'lg' && classes.lg,
        size === 'md' && classes.md,
        size === 'xs' && classes.xs,
        props.value !== null && props.value !== "" && classes.completed
    )

    if (mode === 'view') {
        return <span>{items?.find(item => item.id === props.value)?.name || ''}</span>
    }

    return (
        <div className={className} style={{ width, border: 'none', marginBottom: error ? 10 : 0 }}>
            <Select
                allowClear
                disabled={disabled}
                getPopupContainer={trigger => trigger.parentNode}
                className={classNames}
                dropdownStyle={{ zIndex: 1000000 }}
                dropdownClassName={classes.dropdown}
                showSearch={showSearch}
                style={{ width: '100%', border: invalid ? '1px solid #DF0E0F' : '', borderRadius: 4 }}
                placeholder={placeholder}
                onSearch={onSearch}
                optionFilterProp="children"
                onChange={(value, option) => {
                    onChange(value, option)
                }}
                //@ts-ignore
                filterOption={(input, option) =>
                    //@ts-ignore
                    option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...props}
                value={props.value ? props.value : undefined}
                open={props?.open || undefined}
            >
                {loading
                    ? <Fallback />
                    : items?.map((item => (
                        <Option key={item.id} value={item.id}>{item.name === undefined ? item.territoryName : item.name}</Option>
                    )))}
            </Select>
            <ErrorText style={{ position: 'absolute' }} error={error} />
            {props.createObject && (error
                ? <div className={classes.error_validation_icon}><ClearIcon /></div>
                : <div className={classes.success_validation_icon}><AcceptIcon /></div>)}
        </div>
    )
})