// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tableRow__2QnfO {\n  cursor: pointer;\n  width: 5%;\n}", "",{"version":3,"sources":["webpack://src/components/RaportAlert/style.module.scss"],"names":[],"mappings":"AAKA;EACI,eAAA;EACA,SAAA;AAJJ","sourcesContent":["\n.container {\n    // width: calc(100vw - 295px);\n}\n\n.tableRow {\n    cursor: pointer;\n    width: 5%;\n}\n\n.container__table {\n    // overflow : auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "style_tableRow__2QnfO"
};
export default ___CSS_LOADER_EXPORT___;
