// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_downloadBtn__1xscc {\n  margin-top: 16px;\n}\n.style_downloadBtn__1xscc a {\n  background: black;\n  color: white;\n  padding: 4px 8px;\n  border-radius: 4px;\n}\n.style_downloadBtn__1xscc a:hover {\n  color: white !important;\n}", "",{"version":3,"sources":["webpack://src/components/GoCountRaport/style.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAER;AADQ;EACI,uBAAA;AAGZ","sourcesContent":[".downloadBtn {\n    margin-top: 16px;\n    a {\n        background: black;\n        color: white;\n        padding: 4px 8px;\n        border-radius: 4px;\n        &:hover {\n            color: white !important\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadBtn": "style_downloadBtn__1xscc"
};
export default ___CSS_LOADER_EXPORT___;
