import React, { useEffect, useState } from 'react'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IReportFilters } from '../../store/reports/types'
import { ReportsStore } from '../../store/reports/Reports'
import { observer } from 'mobx-react-lite'
// import { Table } from './Table/Table'
import { NoData } from '../common/Nodata/NoData'
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table'
import { Fallback } from '../helpers/Fallback/Fallback'
const s = require('./style.module.scss')

export const GoCountChop = observer(({ }) => {

    const {
        chop,
        chopLoading,
        sendReportChops
    } = ReportsStore

    const defaultTitle = () => 'Кол-во выездов (ст.орг)';



    const requestHandler = (payload: IReportFilters) => {
        sendReportChops(payload)
    }


    const columns = [
        {
            title: 'Общее количество',
            dataIndex: 'all',
            key: 'all',
            width : 100
        },
        {
            title: 'Ложные',
            dataIndex: 'fake',
            key: 'fake',
            width : 100
        },
        {
            title: 'Боевые',
            dataIndex: 'combat',
            key: 'combat',
            width : 100
        },
        {
            title: 'Отмененные',
            dataIndex: 'canseled',
            key: 'canseled',
            width : 100
        },
    ];


    const dataSource = [
        {
            key: 1,
            all: chop?.all,
            fake: chop?.fake,
            combat: chop?.combat,
            canseled: chop?.canseled,
        },
    ];


    const [activeTable, setActiveTable] = useState<boolean>(false)
    return (
        <div className={s.container}>
            <div className={s.container__header}>
                <TableFiltersBlock flag={'goCountChop'} xlsxFlag={'ExternalDepartureChopCount'} request={requestHandler} setActiveTable={setActiveTable} />
            </div>
            <div className={s.container__table}>
                {chop ? <Table
                    size='small'
                    rowClassName={s.tableRow}
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    title={defaultTitle}
                    loading={chopLoading}
                    pagination={false}
                /> : chopLoading ? <Fallback/> :  <NoData />}
            </div>
        </div>
    )
})