// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_body__1hS_- {\n  padding: 24px;\n}\n.style_body__list__3mhO_ {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n.style_body__list__item__2TGrW {\n  display: flex;\n  flex-direction: column;\n  width: 40%;\n  margin-bottom: 16px;\n}\n.style_body__list__item__title__2wW18 {\n  margin-bottom: 8px;\n  font-style: normal;\n  font-size: 14px;\n  line-height: 24px;\n  color: #ced4da;\n  font-weight: bold;\n}\n.style_body__list__item__value__3o7tN {\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 24px;\n}", "",{"version":3,"sources":["webpack://src/components/Dislocation/InfoModal/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;EACA,eAAA;AACR;AACQ;EACI,aAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;AACZ;AACY;EACI,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAChB;AAEY;EACI,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAAhB","sourcesContent":[".body {\n    padding: 24px;\n\n    &__list {\n        display: flex;\n        justify-content: space-between;\n        flex-wrap: wrap;\n\n        &__item {\n            display: flex;\n            flex-direction: column;\n            width: 40%;\n            margin-bottom: 16px;\n\n            &__title {\n                margin-bottom: 8px;\n                font-style: normal;\n                font-size: 14px;\n                line-height: 24px;\n                color: #ced4da;\n                font-weight: bold;\n            }\n\n            &__value {\n                font-style: normal;\n                font-weight: normal;\n                font-size: 14px;\n                line-height: 24px;\n            }\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "style_body__1hS_-",
	"body__list": "style_body__list__3mhO_",
	"body__list__item": "style_body__list__item__2TGrW",
	"body__list__item__title": "style_body__list__item__title__2wW18",
	"body__list__item__value": "style_body__list__item__value__3o7tN"
};
export default ___CSS_LOADER_EXPORT___;
