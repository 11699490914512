import React from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'

interface IMobileGoExternalObjectsProps {
    items : any
    loading: any
  }

export const MobileGoExternalObjects: React.FC<IMobileGoExternalObjectsProps> = ({items, loading}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
                <Card style={{margin: '15px 0 15px 0'}} key={element.id} title={titleCard(element.name)} size='default'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Пульт :</div>
                            <div>
                                {element?.pult}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Наименование :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.name === 'string' ? element?.name : ''}>
                                    {element?.name ? element?.name.split('').length >= 25 ? `${element?.name.substr(0, 25)}...` : element?.name : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Адрес :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.address === 'string' ? element?.address : ''}>
                                    {element?.address ? element?.address.split('').length >= 25 ? `${element?.address.substr(0, 25)}...` : element?.address : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Ложные :</div>
                            <div>
                                {element?.fake ? element?.fake : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Боевые :</div>
                            <div>
                                {element?.combat ? element?.combat : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Всего :</div>
                            <div>
                                {element?.count ? element?.count : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ЧОП :</div>
                            <div>
                                {element?.chop_full_name ? element?.chop_full_name : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Номер договора :</div>
                            <div>
                                {element?.сontract_number ? element?.сontract_number : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Дата договора :</div>
                            <div>
                                {element?.contract_date ? element?.contract_date : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Вариант оплаты :</div>
                            <div>
                                {element?.payment_option ? element?.payment_option : '-'}
                            </div>
                        </div>
                </Card>
            )}
            />
    </Space>
  )
}