import React from 'react'
import clsx from "clsx";

const classes = require('./classes.module.scss')

interface IErrorTextProps {
    error?: string
    [key: string]: any
}

export const ErrorText: React.FC<IErrorTextProps> = ({error, ...props}) => {
    const classNames = clsx(classes.errorText, props?.className)
    if(!error) return null;
    return (
        <div {...props} className={classNames}>
            {error}
        </div>
    )
}