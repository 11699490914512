import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import ListItem from "@material-ui/core/ListItem";
import { IReportFilters } from '../../store/reports/types'
import { Tooltip, Table, Pagination } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { MobileGoCountRaports } from './components/MobileGoCountRaport';
const s = require('./style.module.scss')

interface IGoCountRaportProps {

}

export const GoCountRaport: React.FC<IGoCountRaportProps> = observer(({ }) => {
    const {
        getGoCountRaport,
        goCountRaport,
        goCountRaportLoading
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        getGoCountRaport({...payload, isCancled: false});
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)

    useEffect(() => {
        requestHandler({})
    }, [])

        //Для таблицы
        const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
        const defaultTitle = () => 'Кол-во выездов (рапорт)';
    
    
        interface DataType {
            key?: string | number
            pult: string
            name: string
            address: string
            fake: string
            combat: string
            count: string
            territory: string
            gbrs_string: string
        }
    
        const columns: ColumnsType<DataType> = goCountRaport?.headers.map((element: any, index: number) => {
            return ({
                key: element.name,
                title: element.name,
                dataIndex: element.mnemo,
                ellipsis: {
                    showTitle: false,
                },
                width: 180,
                render: (address: any) => {
                    return (
                        <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    )
                },
            });
        }) || [];
    
        const dataSource: DataType[] = goCountRaport?.data
            ? goCountRaport.data.map((element: any, index: number) => {
                return {
                    key: element.id,
                    pult: element?.pult,
                    name: element?.name,
                    address: element?.address,
                    fake: element?.fake,
                    combat: element?.combat,
                    count: element?.count,
                    territory: element?.territory,
                    gbrs_string: element?.gbrs_string
                };
            })
            : [];

            const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        <div className={s.container__header}>
            <TableFiltersBlock  xlsxFlag={'DepartureCountReport'} request={requestHandler} flag={'GoCountRaport'} setActiveTable={setActiveTable} />
        </div>
        <div className={s.container__table}>
        {mobile ? 
            <>
                {/* <Pagination 
                    onChange = {
                        (page, pageSize) => {
                            setPage(page)
                            setPageSize(pageSize)
                        }
                    }
                    defaultPageSize = {pageSize}
                    defaultCurrent = {page}
                    total = {80}
                    pageSizeOptions = {[25, 50, 75, 100]}
                    current={page} */}
                   {/* />  */}
                   <MobileGoCountRaports
                   loading={goCountRaportLoading}
                    items={goCountRaport?.data}/>
                
            </>
         
            :
            <Table
                size='large'
                rowClassName={s.tableRow}
                columns={columns}
                dataSource={dataSource}
                bordered
                title={defaultTitle}
                scroll={scroll}
                loading={goCountRaportLoading}
                pagination={{ position: ['topLeft', 'bottomLeft'], pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            />
        }
    
        
        </div>
    </div>
})