import React, { useEffect } from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';
import pdf from '../../../icons/pdficon.svg'

  interface IMobileRaportsProps {
    onItemClick : (element : any) => void
    onClickGetPdf : (element : number) => void
    items : any
    loading: any
  }

export const MobileRaports: React.FC<IMobileRaportsProps> = ({onItemClick, onClickGetPdf, items, loading}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}
  useEffect(()=> {
    console.log(items)
  },[items])

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
          <Card style={{margin: '15px 0 15px 0'}} actions={[<div onClick={() => { onItemClick(element) }}>Еще</div>, <img onClick={() => { onClickGetPdf(element.id) }} src={pdf} style={{
            width: '20px',
            height: '20px',
            cursor: 'pointer',
          }} alt="pdf" />]} key={element.id} title={titleCard(element.object_name)} size='default'>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Дата :</div>
              <div>
                {element?.created_at && formatIsoToDate(element?.created_at) + ' ' + formatIsoToTime(element?.created_at)}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>ПЦО :</div>
              <div>
                {element?.pco?.name}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Система :</div>
              <div>
                {element?.system?.name}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Пульт :</div>
              <div>
                {element?.pult}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Объект :</div>
              <div>
                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.object_name === 'string' ? element?.object_name : ''}>
                  {element?.object_name ? element?.object_name.split('').length >= 25 ? `${element?.object_name.substr(0, 25)}...` : element?.object_name : '-'}
                </Tooltip>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Адрес :</div>
              <div>
                <Tooltip trigger="hover" placement="topLeft" title={typeof element.object_address === 'string' ? element.object_address : ''}>
                  {element.object_address ? element.object_address.split('').length >= 25 ? `${element.object_address.substr(0, 25)}...` : element.object_address : '-'}
                </Tooltip>
              </div>
            </div>

            {element?.external_state &&
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ fontWeight: 'bold' }}>Состояние :</div>
                <div>
                  <Tooltip trigger="hover" placement="topLeft" title={typeof element?.external_state === 'string' ? element?.external_state : ''}>
                    {element?.external_state ? element?.external_state.split('').length >= 25 ? `${element?.external_state.substr(0, 25)}...` : element?.external_state : '-'}
                  </Tooltip>
                </div>
              </div>
            }

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Время сработки :</div>
              <div>
                <div>
                  {element?.time_work ? formatIsoToDate(element?.time_work) + ` ` + formatIsoToTime(element?.time_work) : '-'}
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Время приема в обработку :</div>
              <div>
                <div>
                  {element?.time_in ? formatIsoToDate(element?.time_in) + ` ` + formatIsoToTime(element?.time_in) : '-'}
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Время вызова ГБР :</div>
              <div>
                {element?.time_call_gbr ? formatIsoToDate(element?.time_call_gbr) + ` ` + formatIsoToTime(element?.time_call_gbr) : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>ГБР :</div>
              <div>
                {element?.all_name_gbrs ? element?.all_name_gbrs : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Время прибытия ГБР :</div>
              <div>
                {element?.all_time_arrive_gbr_gbrs ? element?.is_red_time_arrive_gbrs ? <div style={{ color: 'red' }}>{element?.all_time_arrive_gbr_gbrs}</div> : element?.all_time_arrive_gbr_gbrs : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Время завершения тревоги :</div>
              <div>
                {element?.date_close_event ? formatIsoToDate(element?.date_close_event) + ` ` + formatIsoToTime(element?.date_close_event) : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Время убытия с дислокации :</div>
              <div>
                {element?.time_departure_gbr ? element?.time_departure_gbr : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Тип события :</div>
              <div>
                {element?.element?.event_type ? element?.element?.event_type : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Тип тревоги :</div>
              <div>
                {element?.element?.alert_type ? element?.element?.alert_type : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Вид тревоги :</div>
              <div>
                {element?.alarm_kind?.name ? element?.alarm_kind?.name : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Тип дислокации :</div>
              <div>
                {element?.element?.dislocation_type ? element?.element?.dislocation_type : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Причина дислокации :</div>
              <div>
                {element?.reason_name ? element?.reason_name : '-'}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Оператор принял :</div>
              <div>
              <Tooltip trigger="hover" placement="topLeft" title={typeof element?.operator_taked === 'string' ? element?.operator_taked : ''}>
                  {element?.operator_taked ? element?.operator_taked.split('').length >= 20 ? `${element?.operator_taked.substr(0, 20)}...` : element?.operator_taked : '-'}
                </Tooltip>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Оператор выслал :</div>
              <div>
                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.operator_sent === 'string' ? element?.operator_sent : ''}>
                  {element?.operator_sent ? element?.operator_sent.split('').length >= 20 ? `${element?.operator_sent.substr(0, 20)}...` : element?.operator_sent : '-'}
                </Tooltip>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Комментарий :</div>
              <div>
                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.report_comment === 'string' ? element?.report_comment : ''}>
                  {element?.report_comment ? element?.report_comment.split('').length >= 25 ? `${element?.report_comment.substr(0, 25)}...` : element?.report_comment : '-'}
                </Tooltip>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>Администратор :</div>
              <div>
                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.chief === 'string' ? element?.chief : ''}>
                  {element?.chief ? element?.chief.split('').length >= 25 ? `${element?.chief.substr(0, 25)}...` : element?.chief : '-'}
                </Tooltip>
              </div>
            </div>

            {element?.chief && <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>ЧОП :</div>
              <div>
                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.chop_full_name === 'string' ? element?.chop_full_name : ''}>
                  {element?.chop_full_name ? element?.chop_full_name.split('').length >= 25 ? `${element?.chop_full_name.substr(0, 25)}...` : element?.chop_full_name : '-'}
                </Tooltip>
              </div>
            </div>}
          </Card>
            )}
            />
    </Space>
  )
}
