import { observer } from 'mobx-react-lite'
import React, {JSX, useEffect, useState} from 'react'
import { ReportsStore } from '../../store/reports/Reports'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IReportFilters } from '../../store/reports/types'
import { ColumnsType } from 'antd/lib/table'
import { Table, Tooltip } from 'antd'
import { formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { MobileGoRaport } from './component/MobileGoRaport'
const s = require('./style.module.scss')

interface IGoRaportProps {

}

export const GoRaport: React.FC<IGoRaportProps> = observer(({ }) => {
    const {
        getNewGoReport,
        newGoRaport,
        newGoRaportLoading
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        getNewGoReport(payload);
    }


    useEffect(() => {
        requestHandler({})
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
    const defaultTitle = () => 'Выезды (рапорт)';

    interface DataType {
        key?: string
        raports?: Object[]
        total?: string | number
        totalDeparture?: string | number
        totalReactions?: string | number
    }

    const columns: ColumnsType<DataType> = newGoRaport?.headers.map((element: any, index: number) => {
        return ({
            key: element.name,
            title: element.name,
            dataIndex: element.mnemo,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
            render: (address: any) => {
                return (
                    <div>
                        <Tooltip trigger="hover" placement="topLeft"
                                 title={typeof address === 'string' ? address : ''}>
                            {typeof address === 'string' ? address : ''}
                        </Tooltip>
                    </div>
                )
            },
        });
    }) || [];


    const dataSource = newGoRaport?.data
        // @ts-ignore
        ? newGoRaport.data?.raports.map((element: any, index: number) => {
            return {
                key: element?.id,
                created_at: element?.created_at && formatIsoToDate(element?.created_at) + ' ' + formatIsoToTime(element?.created_at),
                pco: element?.pco?.name,
                system: element?.system?.name,
                pult: element?.pult,
                object_name: element?.object_name,
                object_address: element?.object_address,
                external_state: element?.external_state,
                time_work: element?.time_work && formatIsoToDate(element?.time_work) + ` ` + formatIsoToTime(element?.time_work),
                time_in: element?.time_in && formatIsoToDate(element?.time_in) + ` ` + formatIsoToTime(element?.time_in),
                time_call_gbr: element?.time_call_gbr && formatIsoToDate(element?.time_call_gbr) + ` ` + formatIsoToTime(element?.time_call_gbr),
                all_name_gbrs: element?.all_name_gbrs,
                all_time_accept_gbr: element?.all_time_accept_gbr,
                all_time_departure_gbr: element?.all_time_departure_gbr,
                all_time_arrive_gbr_gbrs: element?.all_time_arrive_gbr_gbrs && element?.is_red_time_arrive_gbrs ? <div style={{ color: 'red' }}>{element?.all_time_arrive_gbr_gbrs}</div> : element?.all_time_arrive_gbr_gbrs,
                date_close_event: element?.date_close_event && formatIsoToDate(element?.date_close_event) + ` ` + formatIsoToTime(element?.date_close_event),
                time_departure_gbr: element?.time_departure_gbr && formatIsoToDate(element?.time_departure_gbr) + ` ` + formatIsoToTime(element?.time_departure_gbr),
                event_type: element?.event_type?.name,
                alert_type: element?.alert_type,
                alarm_kind: element?.alarm_kind?.name ? element.alarm_kind.name : 'Не указано',
                dislocation_type: element?.dislocation_type,
                reason_name: element?.reason_name,
                operator_taked: element?.operator_taked,
                operator_sent: element?.operator_sent,
                operator_closed_full_name: element?.operator_closed_full_name,
                report_comment: element?.report_comment,
                chief: element?.chief,
                chop_full_name: element?.chop_full_name
            };
        })
        : [];
    dataSource?.push({
             created_at: 'Итого выездов',
            pco: `${newGoRaport?.data?.totalDeparture}`
        },
        {
            created_at: 'Реагирование',
            pco: `${newGoRaport?.data?.totalReactions}`
        },
        {
            created_at: 'Итого',
            pco: `${newGoRaport?.data?.total}`
    },
    )

    const createFooter = () => {
        return (
            <div className={s.antTableFooter}>
                <div className={s.footerRow}>
                    <div>Итоги выездов</div>
                    <div>{newGoRaport?.data?.totalDeparture}</div>
                </div>
                <div className={s.footerRow}>
                    <div>Реагирование</div>
                    <div>{newGoRaport?.data?.totalReactions}</div>
                </div>
                <div className={s.footerRow}>
                    <div>Итого</div>
                    <div>{newGoRaport?.data?.total}</div>
                </div>
            </div>
        )
    }
    
        const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        <div className={s.container__header}>
            <TableFiltersBlock flag={'goCountRaportWithReact'} xlsxFlag='DepartureReport' request={requestHandler} setActiveTable={setActiveTable}  />
        </div>
        <div className={s.container__table}>
            {mobile ? 
            <MobileGoRaport
            items={newGoRaport?.data}
            loading = {newGoRaportLoading}/>
            :
                <div>
                    <Table
                        size='large'
                        rowClassName={s.tableRow}
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        title={defaultTitle}
                        scroll={scroll}
                        loading={newGoRaportLoading}
                        pagination={{ position: ['topLeft', 'bottomLeft'], pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
                        footer={createFooter}
                    />
                </div>
            }
            
        </div>
    </div>
})