import React, { useEffect } from 'react'
import { Card, List, Space,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';

interface IMobileDislocationProps {
    items : any
    onItemClick : (element : any) => void
    loading: any
  }

export const MobileDislocation: React.FC<IMobileDislocationProps> = ({items, onItemClick, loading}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

useEffect(() => {
    
})

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
         <List pagination={{ position: "both", pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
            dataSource = {items}
            loading = {loading}
            renderItem={(element: any) => (
                <Card style={{margin: '15px 0 15px 0'}} actions={[<div onClick={() => { onItemClick(element) }}>Еще</div>]} key={element.id} title={titleCard(element.name)} size='default'>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Наименование :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.name === 'string' ? element?.name : ''}>
                                    {element?.name ? element?.name.split('').length >= 20 ? `${element?.name.substr(0, 20)}...` : element?.name : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Время убытия :</div>
                            <div>
                                {element?.timeExit ? formatIsoToDate(element?.timeExit) + ` ` + formatIsoToTime(element?.timeExit) : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Адрес :</div>
                            <div>
                                <Tooltip trigger="hover" placement="topLeft" title={typeof element?.address === 'string' ? element?.address : ''}>
                                    {element?.address ? element?.address.split('').length >= 20 ? `${element?.address.substr(0, 20)}...` : element?.address : '-'}
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Время вызова :</div>
                            <div>
                                {element?.timeCall ? formatIsoToDate(element?.timeCall) + ` ` + formatIsoToTime(element?.timeCall) : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>ГБР :</div>
                            <div>
                                {element?.gbr ? element?.gbr : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Причина :</div>
                            <div>
                                {element?.reason ? element?.reason : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Тип события :</div>
                            <div>
                                {element?.eventType ? element?.eventType : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Пульт :</div>
                            <div>
                                {element?.pult ? element?.pult : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Дислокации с начала месяца :</div>
                            <div>
                                {element?.dislocationCountByMounth ? element?.dislocationCountByMounth : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Тип дислокации :</div>
                            <div>
                                {element?.dislocationType ? element?.dislocationType : '-'}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ fontWeight: 'bold' }}>Тип тревоги :</div>
                            <div>
                                {element?.alertType ? element?.alertType : '-'}
                            </div>
                        </div>

            
                </Card>
            )}
          />
    </Space>
  )
}