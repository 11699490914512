// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".classes_fallback_wrap__2hCx- {\n  min-height: 50px;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.classes_fallback_loader__9xehr {\n  margin-top: 20px;\n}", "",{"version":3,"sources":["webpack://src/components/helpers/Fallback/classes.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".fallback_wrap {\n  min-height: 50px;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.fallback_loader {\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fallback_wrap": "classes_fallback_wrap__2hCx-",
	"fallback_loader": "classes_fallback_loader__9xehr"
};
export default ___CSS_LOADER_EXPORT___;
