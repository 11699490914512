import React from 'react';
import { RouterIndex } from "./routers/RouterIndex";
import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store";


export const App = ({ }) => (

    <Provider store={store} >
        <RouterIndex />
    </Provider >
)