// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_footerRow__1E4Vj {\n  display: grid;\n  grid-template-columns: 180px 180px;\n  font-weight: 700;\n  margin: 0;\n}\n.style_footerRow__1E4Vj div {\n  padding: 16px;\n}\n\n.style_antTableFooter__1BqTT {\n  padding: 0;\n}", "",{"version":3,"sources":["webpack://src/components/GoRaport/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kCAAA;EACA,gBAAA;EACA,SAAA;AACF;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,UAAA;AACF","sourcesContent":[".footerRow {\n  display: grid;\n  grid-template-columns: 180px 180px;\n  font-weight: 700;\n  margin: 0;\n  div {\n    padding: 16px;\n  }\n}\n\n.antTableFooter {\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerRow": "style_footerRow__1E4Vj",
	"antTableFooter": "style_antTableFooter__1BqTT"
};
export default ___CSS_LOADER_EXPORT___;
