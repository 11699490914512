import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
//import { Table } from '../common/Table/Table'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IRaport, IRaportGbrItem, IRaportMonth, IRaportSystemItem, IRaportVK, IReportFilters } from '../../store/reports/types'
// import { InfoModal } from './components/InfoModal'
import { List, Table, TableColumnsType, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table'
import { TEnumItem, formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { Fallback } from '../helpers/Fallback/Fallback'
import { NoData } from '../common/Nodata/NoData'
// import { MobileRaports } from './components/MobileRaports'
const s = require('./styles.module.scss')



interface IOperatorRaportsProps {

}

export const OperatorRaports: React.FC<IOperatorRaportsProps> = observer(({ }) => {

    const [openPopup, setOpenPopup] = React.useState<boolean>(false)
    const [clickedItem, setClickedItem] = React.useState<IRaportMonth | null>(null)
    const [arrData, setArrayData] = useState([])

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 300, y: 700 };
    const defaultTitle = () => 'Обработка по месяцам';
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)
    const [payload, setPayload] = useState<IReportFilters | null>(null)



    // useEffect(() => {
    //     if (!payload) {
    //         requestHandler({
    //             isExternal: false,
    //             isDeleted: false,
    //             count: 25,
    //             offset: 0
    //         });
    //     } else {
    //         getReportMonth({ ...payload, count: pageSize, offset: pageSize * (page - 1)});
    //     }
    // }, [page, pageSize])

    useEffect(() => {
        setPage(1)
    }, [pageSize])


    const {
        getReportOperator,
        operatorRaport,
        operatorRaportLoading,
        getReportXlSX
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        setPage(1)
        setPayload(payload)
        getReportOperator({ ...payload, count : pageSize, offset: pageSize * (page - 1)});
    }

    const onItemClick = (item: any) => {
        setClickedItem(item);
        setArrayData(operatorRaport?.data)
        setOpenPopup(true);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        windowWidth > 800 ? setMobile(false) : setMobile(true)
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth, mobile]);

    interface DataType {
        "pco": string,
        "operator": string,
                "alarmsCreated": {
                    "dayTimeEvents": string,
                    "nightTimeEvents": string
                },
                "alarmsClosed": {
                    "dayTimeEvents": string,
                    "nightTimeEvents": string
                },
                "alarmsCancled": {
                    "dayTimeEvents": string,
                    "nightTimeEvents": string
                },
                "dislocationCompleted": string
                
    }
    console.log(operatorRaport)
    const data = operatorRaport?.data?.map((element: any) => {
        return (
            {
                pco: element?.pco,
                operator: element?.operator,
                alarmsCreated_dayTimeEvents: element?.alarmsCreated?.dayTimeEvents,
                alarmsCreated_nightTimeEvents: element?.alarmsCreated?.nightTimeEvents,
                alarmsClosed_dayTimeEvents: element?.alarmsClosed?.dayTimeEvents,
                alarmsClosed_nightTimeEvents: element?.alarmsClosed?.nightTimeEvents,
                alarmsCancled_dayTimeEvents: element?.alarmsCancled?.dayTimeEvents,
                alarmsCancled_nightTimeEvents: element?.alarmsCancled?.nightTimeEvents,
                dislocationCompleted: element?.dislocationCompleted,
            }
        )
    })

    const columns: TableColumnsType<DataType> = [
        {
          title: 'ПЦО',
          dataIndex: 'pco',
          key: 'pco',
          width: 30,
          fixed: 'left',
        },
        {
            title: 'Оператор ФИО',
            dataIndex: 'operator',
            key: 'operator',
            width: 50,
            fixed: 'left',
          },
        {
          title: 'Создал тревог',
          children: [
            {
                title: '08:00 - 20:00',
                dataIndex: 'alarmsCreated_dayTimeEvents',
                key: 'ОС',
                width: 70,
            },
            {
                title: '20:00 - 08:00',
                dataIndex: 'alarmsCreated_nightTimeEvents',
                key: 'ТС',
                width: 70,
            },
        ]
        },
        {
            title: 'Завершил тревог',
            children: [
              {
                  title: '08:00 - 20:00',
                  dataIndex: 'alarmsClosed_dayTimeEvents',
                  key: '08:00 - 20:00',
                  width: 70,
              },
              {
                  title: '20:00 - 08:00',
                  dataIndex: 'alarmsClosed_nightTimeEvents',
                  key: '20:00 - 08:00',
                  width: 70,
              },
          ]
          },
          {
            title: 'Отменил тревог',
            children: [
              {
                  title: '08:00 - 20:00',
                  dataIndex: 'alarmsCancled_dayTimeEvents',
                  key: '08:00 - 20:00',
                  width: 70,
              },
              {
                  title: '20:00 - 08:00',
                  dataIndex: 'alarmsCancled_nightTimeEvents',
                  key: '20:00 - 08:00',
                  width: 70,
              },
          ]
          },
          {
            title: 'Завершил дислокацией',
            dataIndex: 'dislocationCompleted',
            key: 'dislocationCompleted',
            width: 70,
          }
        ]
   
    useEffect(() => {
        console.log(operatorRaport?.data)
    }, [operatorRaport])

    useEffect(() => {
        console.log(data)
    }, [data])

    const onClickGetPdf = (id: number) => {
        getReportXlSX({
            "reportName": "SelectedRaportReport",
            "fileExtension": "pdf",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "id": id
                    }
                }
            ]
        })
    }


    const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        {/* {openPopup && <InfoModal
            closeHandler={() => setOpenPopup(false)}goCountGroup
            i={clickedItem}
            open={openPopup}
            arrData={arrData}
            title={`Рапорт по тревоге  - ${clickedItem?.object_name}`}
        />} */}
        <div className={s.container__header}>
            <TableFiltersBlock flag={'raportOperator'} xlsxFlag={'OperatorReport'} request={requestHandler} setActiveTable={setActiveTable} />
        </div>
        <div className={s.smallTable}>
                    <Table
                        size='small'
                        rowClassName={s.tableRow}
                        columns={columns}
                        dataSource={data}
                        bordered
                        title={() => operatorRaport?.data?.gbr_name}
                        scroll={scroll}
                        loading={operatorRaportLoading}
                        pagination = {false}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => { onItemClick(record) },
                            };
                        }}
                    />
                    </div>
    </div>
})