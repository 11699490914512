// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".classes_checkbox__2ODd4:hover {\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/common/CheckBox/classes.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ","sourcesContent":[".checkbox {\n  &:hover {\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "classes_checkbox__2ODd4"
};
export default ___CSS_LOADER_EXPORT___;
