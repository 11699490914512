import {ReactComponent as MyApplication} from './dashboard.svg';
import {ReactComponent as EyeIcon} from './EyeIcon.svg';
import {ReactComponent as PointsIcon} from './Points.svg';
import {ReactComponent as ClearIcon} from './ClearIcon.svg';
import {ReactComponent as AcceptIcon} from './acceptBlueRound.svg';
import {ReactComponent as ArrowIcon} from './ArrowIcon.svg';
import {ReactComponent as CrossIcon} from './KrestIcon.svg';
// import {ReactComponent as Favourite} from './Favourite.svg';
import {ReactComponent as Calendar} from './Calendar.svg';
import {ReactComponent as History} from './History.svg';
import {ReactComponent as Workers} from './Workers.svg';
import {ReactComponent as Lines} from './Lines.svg';
import {ReactComponent as Additionaly} from './Additionally.svg';
import {ReactComponent as Search} from './Search.svg';
import {ReactComponent as Notification} from './Notification.svg';
import {ReactComponent as CheckMark} from './CheckMark.svg';
import {ReactComponent as AuthorizationLogo} from './AuthorizationLogo.svg';
import {ReactComponent as DownloadBtn} from './DownloadBtn.svg';
import {ReactComponent as MainLogo} from './MainLogo.svg';
import {ReactComponent as Favorites} from './Favorites.svg';
import {ReactComponent as SimpleSelectUp} from './SimpleSelectUp.svg';
import {ReactComponent as SimpleSelectDown} from './SimpleSelectDown.svg';
import {ReactComponent as NodataIcon} from './FileNotFoundIcon.svg';
import {ReactComponent as BinIcon} from './Bin.svg';
import {ReactComponent as EditIcon} from './EditIcon.svg';
import {ReactComponent as HeartIcon} from './LikeIcon.svg';
import {ReactComponent as PencilIcon} from './PencilIcon.svg';
import { ReactComponent as LikeBackgroundIcon } from './LikeIconWithBackground.svg';
import { ReactComponent as ArchiveIcon } from './Archive.svg';
import {ReactComponent as DeleteIcon} from './DeleteIcon.svg';
import {ReactComponent as ReturnIcon} from './Return.svg';




export {
    ReturnIcon,
    DeleteIcon,
    ArchiveIcon,
    CrossIcon,
    PencilIcon,
    LikeBackgroundIcon,
    EditIcon,
    BinIcon,
    SimpleSelectDown,
    HeartIcon,
    NodataIcon,
    SimpleSelectUp,
    MyApplication,
    ArrowIcon,
    ClearIcon,
    AcceptIcon,
    EyeIcon,
    PointsIcon,
    // Favourite,
    Calendar,
    History,
    Workers,
    Lines,
    Additionaly,
    Search,
    Notification,
    CheckMark,
    AuthorizationLogo,
    DownloadBtn,
    MainLogo,
    Favorites,
}