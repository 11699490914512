import React, { useEffect, useState } from 'react'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { ReportsStore } from '../../store/reports/Reports'
import { observer } from 'mobx-react-lite'
import { IReportFilters } from '../../store/reports/types'
import { Table } from 'antd';
const s = require('./style.module.scss')

export const CaesarGet = observer (({}) => {

    const {
        caesarReports,
        caesarReportsLoadnig,
        getCaesarReport
    } = ReportsStore

    const defaultTitleExternal = () => 'Цезарь (внешние тревоги)';
    const defaultTitleInternal = () => 'Цезарь (внутренние тревоги)';

    const requestHandler = (payload: IReportFilters) => {
        getCaesarReport(payload)
    }

    useEffect(() => {
        getCaesarReport({})
    }, [])

    const externalColumns = [
        {
            title: 'Кол-во полученных тревог',
            dataIndex: 'all_cezar_alarm',
            key: 'all_cezar_alarm',
            width : 100
        },
        {
            title: 'Ложные',
            dataIndex: 'fake',
            key: 'fake',
            width : 100
        },
        {
            title: 'Боевые',
            dataIndex: 'combat',
            key: 'combat',
            width : 100
        },
        {
            title: 'Отмененные',
            dataIndex: 'canseled',
            key: 'canseled',
            width : 100
        },
        {
            title: 'Неизвестные',
            dataIndex: 'unknown',
            key: 'unknown',
            width : 100
        }
    ];

    const externalDataSource = [
        {
            key: 1,
            all_cezar_alarm: caesarReports?.external?.all_cezar_alarm,
            fake: caesarReports?.external?.fake,
            combat: caesarReports?.external?.combat,
            canseled: caesarReports?.external?.canseled,
            unknown: caesarReports?.external?.unknown,
        },
    ];


    const internalColumns = [
        {
            title: 'Кол-во отправленных тревог',
            dataIndex: 'all_cezar_alarm',
            key: 'all_cezar_alarm',
            width : 100
        },
        {
            title: 'Ложные',
            dataIndex: 'fake',
            key: 'fake',
            width : 100
        },
        {
            title: 'Боевые',
            dataIndex: 'combat',
            key: 'combat',
            width : 100
        },
        {
            title: 'Отмененные',
            dataIndex: 'canseled',
            key: 'canseled',
            width : 100
        },
        {
            title: 'Неизвестные',
            dataIndex: 'unknown',
            key: 'unknown',
            width : 100
        }
    ];

    const internalDataSource = [
        {
            key: 1,
            all_cezar_alarm: caesarReports?.internal?.all_cezar_alarm,
            fake: caesarReports?.internal?.fake,
            combat: caesarReports?.internal?.combat,
            canseled: caesarReports?.internal?.canseled,
            unknown: caesarReports?.internal?.unknown,
        },
    ];

    const [activeTable, setActiveTable] = useState<boolean>(false)
    return (
        <div className={s.container}>
            <div className={s.container__header}>
                <TableFiltersBlock xlsxFlag={'CezarDepartureCountReport'} request={requestHandler} setActiveTable={setActiveTable} />
            </div>
            <div className={s.container__table}>
            <Table
                    size='small'
                    rowClassName={s.tableRow}
                    columns={externalColumns}
                    dataSource={externalDataSource}
                    bordered
                    title={defaultTitleExternal}
                    loading={caesarReportsLoadnig}
                    pagination={false}
                />
            </div>
            <div className={s.container__table}>
            <Table
                    size='small'
                    rowClassName={s.tableRow}
                    columns={internalColumns}
                    dataSource={internalDataSource}
                    bordered
                    title={defaultTitleInternal}
                    loading={caesarReportsLoadnig}
                    pagination={false}
                />
            </div>
        </div>
    )
})

