// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__table__2Nd0b {\n  margin-top: 20px;\n}\n@media (max-width: 390px) {\n  .style_container__table__2Nd0b {\n    overflow: auto;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/GoCountChop/style.module.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;AADJ;AAEI;EAFJ;IAGQ,cAAA;EACN;AACF","sourcesContent":["\n\n.container__table {\n    margin-top: 20px;\n    @media (max-width : 390px) {\n        overflow: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container__table": "style_container__table__2Nd0b"
};
export default ___CSS_LOADER_EXPORT___;
