import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
//import { Table } from '../common/Table/Table'
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IRaport, IRaportAlert, IRaportGbrItem, IRaportSystemItem, IRaportVK, IReportFilters } from '../../store/reports/types'
// import { InfoModal } from './components/InfoModal'
import { Card, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table'
import { TEnumItem, formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { Fallback } from '../helpers/Fallback/Fallback'
import { NoData } from '../common/Nodata/NoData'
// import { MobileRaports } from './components/MobileRaports'
const s = require('./style.module.scss')



interface IExternalDepartureGeneralCountReporttProps {

}

export const ExternalDepartureGeneralCountReport: React.FC<IExternalDepartureGeneralCountReporttProps> = observer(({ }) => {

    const [openPopup, setOpenPopup] = React.useState<boolean>(false)
    const [clickedItem, setClickedItem] = React.useState<IRaportAlert | null>(null)
    const [arrData, setArrayData] = useState([])

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 1300, y: 700 };
    const defaultTitle = () => 'Подсчет тревог';
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)
    const [payload, setPayload] = useState<IReportFilters | null>(null)



    useEffect(() => {
        if (!payload) {
            requestHandler({
                isExternal: false,
                isDeleted: false,
                count: 25,
                offset: 0
            });
        } else {
            getExternalDepartureGeneralCountReport({ ...payload, count: pageSize, offset: pageSize * (page - 1)});
        }
    }, [page, pageSize])

    useEffect(() => {
        setPage(1)
    }, [pageSize])


    const {
        getExternalDepartureGeneralCountReport,
        ExternalDepartureGeneralCountReport,
        ExternalDepartureGeneralCountReportLoading,
        getReportXlSX
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        setPage(1)
        setPayload(payload)
        getExternalDepartureGeneralCountReport({ ...payload, count : pageSize, offset: pageSize * (page - 1)});
    }

    const onItemClick = (item: any) => {
        setClickedItem(item);
        setArrayData(ExternalDepartureGeneralCountReport?.data)
        setOpenPopup(true);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        windowWidth > 800 ? setMobile(false) : setMobile(true)
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth, mobile]);

    interface DataType {
        // key: string;
        // name: string;
        "pult": string,
        "objectName": string,
        "objectAddress": string,
        "alarmCount": string,
        "alarmCancled": string,
        "timeCallGbr": string,
        "contract_date": string,
        "chop_full_name": string,
        "contract_number": string
        "payment_option": string,
        "combatAlarmCount": string,
        "fakeAlarmCount": string
    }

    const columns: ColumnsType<DataType> = ExternalDepartureGeneralCountReport?.headers.map((element: any, key: number) => {
        return ({
            key: element.name,
            title: element.name,
            dataIndex: element.mnemo,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
            columnWidth: 180,
            render: (address: any, key: any) => {
                return (
                    <Tooltip trigger="hover" placement="topLeft" title={address}>
                        {typeof address === 'string' ? address : ''}
                    </Tooltip>
                )
            },
        });
    }) || [];
   

    const data: DataType[] = ExternalDepartureGeneralCountReport?.data.map((element: any) => {
        return (
            {
                pult: element.pult,
                objectName: element.objectName,
                objectAddress: element.objectAddress,
                alarmCount: element.alarmCount,
                alarmCancled: element.alarmCancled,
                timeCallGbr: element.timeCallGbr,
                departureCount: element.departureCount,
                contract_date: element.contract_date,
                chop_full_name: element.chop_full_name,
                contract_number: element.contract_number,
                payment_option: element.payment_option,
                combatAlarmCount: element.combatAlarmCount,
                fakeAlarmCount: element.fakeAlarmCount
            }
        )
    })

    const onClickGetPdf = (id: number) => {
        getReportXlSX({
            "reportName": "SelectedRaportReport",
            "fileExtension": "pdf",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "id": id
                    }
                }
            ]
        })
    }


    const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        <div className={s.container__header}>
            <TableFiltersBlock flag={'ExternalDepartureGeneralCountReport'} xlsxFlag={'ExternalDepartureGeneralCountReport'} request={requestHandler} setActiveTable={setActiveTable} />
        </div>
                
                <div className={s.container__table}>
                        {ExternalDepartureGeneralCountReport?.data && ExternalDepartureGeneralCountReport?.data?.length === 0 ?
                        ExternalDepartureGeneralCountReportLoading ? <Fallback/> :  <NoData />
                    :  
                    <Table
                        size='small'
                        rowClassName={s.tableRow}
                        columns={columns}
                        dataSource={data}
                        bordered
                        title={defaultTitle}
                        scroll={scroll}
                        loading={ExternalDepartureGeneralCountReportLoading}
                        pagination={{
                            position: ['topLeft', 'bottomLeft'],
                            pageSizeOptions: [25, 50, 75, 100],
                            defaultPageSize: pageSize,
                            onChange: (page, pageSize) => {
                                setPage(page)
                                setPageSize(pageSize)
                            },
                            defaultCurrent : page,
                            total : ExternalDepartureGeneralCountReport?.total,
                            current : page
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => { onItemClick(record) },
                            };
                        }}
                    />
                    }
                    
                </div>
    </div>
})