import React from "react";
import { observer } from "mobx-react-lite";
import { Fade } from "@material-ui/core";
import { NodataIcon } from "../../../icons/icons";

const s = require('./NoData.module.scss')

interface INoDataProps {
}

export const NoData: React.FC<INoDataProps> = observer(({
}) => {
    return <Fade in timeout={200}>
        <div className={s.container}>
            <NodataIcon />
            <div>
                Нет информации по данному запросу
            </div>
        </div>
    </Fade>
})