// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_body__list__n4MUm {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n.style_body__list__item__2dAtv {\n  display: flex;\n  flex-direction: column;\n  width: 40%;\n  margin-bottom: 16px;\n}\n.style_body__list__item__title__4PHUD {\n  margin-bottom: 8px;\n  font-style: normal;\n  font-size: 14px;\n  line-height: 24px;\n  color: #ced4da;\n  font-weight: bold;\n}\n.style_body__list__item__value__2V0wk {\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 24px;\n}", "",{"version":3,"sources":["webpack://src/components/JournalActions/component/style.module.scss"],"names":[],"mappings":"AAGI;EACI,aAAA;EACA,mBAAA;EAEA,uBAAA;EACA,mBAAA;AAHR;AAMQ;EACI,aAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;AAJZ;AAMY;EACI,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAJhB;AAOY;EACI,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AALhB","sourcesContent":[".body {\n    // padding: 24px;\n\n    &__list {\n        display: flex;\n        flex-direction: row;\n        // justify-content: start;\n        justify-content: center;\n        align-items: center;\n        // flex-wrap: wrap;\n\n        &__item {\n            display: flex;\n            flex-direction: column;\n            width: 40%;\n            margin-bottom: 16px;\n\n            &__title {\n                margin-bottom: 8px;\n                font-style: normal;\n                font-size: 14px;\n                line-height: 24px;\n                color: #ced4da;\n                font-weight: bold;\n            }\n\n            &__value {\n                font-style: normal;\n                font-weight: normal;\n                font-size: 14px;\n                line-height: 24px;\n            }\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body__list": "style_body__list__n4MUm",
	"body__list__item": "style_body__list__item__2dAtv",
	"body__list__item__title": "style_body__list__item__title__4PHUD",
	"body__list__item__value": "style_body__list__item__value__2V0wk"
};
export default ___CSS_LOADER_EXPORT___;
