import React, { FC, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ArchiveIcon, BinIcon, Calendar, CheckMark, DeleteIcon, Favorites, History, MainLogo, MyApplication, Notification, Workers } from "../../icons/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import { ROUTE_PATH } from "../../routers/paths.main";
import { Fallback } from "../helpers/Fallback/Fallback";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../redux/store";
import BurgerMenuIcon from '../../icons/burgerMenu'
import { Fade } from "@material-ui/core";
import { getMyInfo } from "../../redux/reducers/auth/actions/actions";

const s = require('./style.module.scss')

interface ISideBarContentProps {
    totalCount?: string,

    [key: string]: any
}

export const Sidebar: React.FC<ISideBarContentProps> = ({ children }) => {

    const isMobile: boolean = (window.outerWidth <= 1024)

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const [openSideBar, setOpenSideBar] = React.useState<boolean>(true)
    const [showExit, setShowExit] = useState<boolean>(false)
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [openAppliation, setOpenAppliation] = React.useState(false);
    const [openAdministration, setOpenAdministration] = React.useState(false);

    const handleClickAppliation = () => {
        setOpenAppliation(!openAppliation);
    };
    const handleClickAdministration = () => {
        setOpenAdministration(!openAdministration);
    };

    const logOut = () => {
        localStorage.clear()
    }

    const PAGE_SIZE: number = 4

    const [currentPage, setCurrentPage] = useState<number>(1)
    useEffect(() => {
        /*requestApplicationsCounts()*/
    }, [])

    const dispatch = useDispatch()



    const myFormviews = useSelector((state: TRootState) => state.authReducer.myInfo?.formviews)
    const myInfo = useSelector((state: TRootState) => state.authReducer.myInfo)

    return (
        <div className={s.page}>
            <div className={s.sidebar}
                style={false ? {
                    display: 'none'
                } : {}}
            >
                <div className={s.sideBarHeader}>
                    <Link to={ROUTE_PATH.goCountGroup} className={s.main_logo_wrap}>
                        <MainLogo />
                    </Link>
                    <div className={s.authMenuBlock}>
                        {/*isMobile && <div className={s.notification_login}>
                            <div
                                onMouseLeave={() => setShowNotification(false)}
                                style={!showNotification
                                    ? { display: 'none' }
                                    : { position: 'absolute', zIndex: 10, right: '10%', background: 'white', top: '10%' }}
                            >
                                <Notifications />
                            </div>
                            <div onClick={() => setShowExit(!showExit)} style={{ margin: '0 10px', color: 'white' }}>
                                {myInfo?.name}
                            </div>
                            <CheckMark style={showExit ? {
                                cursor: 'pointer',
                                transform: 'rotateX(180deg)',
                                transition: '1s'
                            } : { cursor: 'pointer', transition: '1s' }} onClick={() => setShowExit(!showExit)} />
                        </div>*/}

                        {/*showExit && isMobile && <Fade in timeout={500}>
                            <div className={s.exitBlock}>
                                <Link to={ROUTE_PATH.authorization}>
                                    <div onClick={() => localStorage.clear()}>
                                        Выход
                                    </div>
                                </Link>
                            </div>
                    </Fade>*/}

                        {/* {isMobile && <ListItem button style={{ width: 32, height: 32, paddingLeft: 'unset', paddingRight: 'unset' }} onClick={() => setOpenSideBar(!openSideBar)}>
                                        <BurgerMenuIcon color={'white'} />
                                    </ListItem>
                        } */}
                    </div>
                </div>


                {openSideBar && <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={s.root}
                >
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        {/* <ListItemIcon>
                            <MyApplication />
                        </ListItemIcon> */}
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.goCountRaport}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Кол-во выездов (рапорт) </span>
                        </NavLink>
                    </div>
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.goExternalObject}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Выезды на объекты (ст.орг.)</span>
                        </NavLink>
                    </div>
                    {/* <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.goCountGroup}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Кол-во выездов (по группе)</span>
                        </NavLink>
                    </div> */}
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.goRaport}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Выезды (рапорт)</span>
                        </NavLink>
                    </div>
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.dislocations}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Дислокации</span>
                        </NavLink>
                    </div>
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.raports}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Рапорты</span>
                        </NavLink>
                    </div>
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.caesar}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Цезарь</span>
                        </NavLink>
                    </div>

                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.numberOfDepatures}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Кол-во выездов (ст.орг)</span>
                        </NavLink>
                    </div>

                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.externalDepartureGeneralCountReport}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Стр. орг. Общий</span>
                        </NavLink>
                    </div>
                    
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.raportsVK}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Нажатие кнопок (VK Teams)</span>
                        </NavLink>
                    </div>

                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.raportsAlert}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Подсчет тревог</span>
                        </NavLink>
                    </div>

                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.journalActions}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Журнал действий</span>
                        </NavLink>
                    </div>
                    
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.raportsMonths}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Отчет по месяцам</span>
                        </NavLink>
                    </div>
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.operatorRaports}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Рапорт операторы</span>
                        </NavLink>
                    </div>
                    <div className={s.list_item} onClick={handleClickAppliation}>
                        <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.settings}>
                            <span onClick={() => isMobile && setOpenSideBar(true)}>Настройки</span>
                        </NavLink>
                    </div>

                    <div className={s.list_item} onClick={logOut}>
                        <Link to={ROUTE_PATH.login}>
                            <span>Выйти</span>
                        </Link>
                    </div>
                </List>}
            </div>

            <div className={s.content}>
                {children}
            </div>
        </div>
    )
}