// @ts-nocheck
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ReportsStore } from '../../store/reports/Reports'
import { Table, Tooltip } from 'antd';
import { TableFiltersBlock } from '../TableFiltersBlock/TableFiltersBlock'
import { IDislocationsItem, IReportFilters } from '../../store/reports/types'
import { InfoModal } from './InfoModal/InfoModal'
import { ColumnsType } from 'antd/lib/table'
import { formatIsoToDate, formatIsoToTime } from '../helpers/utils';
import { MobileDislocation } from './component/MobileDislocation';
const s = require('./style.module.scss')

interface IDislocationProps {

}

export const Dislocation: React.FC<IDislocationProps> = observer(({ }) => {

    const {
        getDislocations,
        dislocations,
        dislocationsLoading
    } = ReportsStore

    const requestHandler = (payload: IReportFilters) => {
        if(payload.isLessTwoHours){
            getDislocations(payload);
        }else{
            getDislocations({
                isLessTwoHours: false,
                isNoDeparture: false
            })
        }

    }

   

    const onItemClick = (item: any) => {
        setClickedItem(item);
        setArrayData(dislocations?.data)
        debugger
        // dislocations.data.find(element => element.id === item.key)
        setOpenPopup(true);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const [openPopup, setOpenPopup] = React.useState<boolean>(false)
    const [clickedItem, setClickedItem] = React.useState<IDislocationsItem | null>(null)
    const [arrData, setArrayData] = useState([])

    useEffect(() => {
        requestHandler({

        })
    }, [])

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        windowWidth > 800 ? setMobile(false) : setMobile(true)
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth, mobile]);

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
    const defaultTitle = () => 'Дислокации';


    interface DataType {
        key?: string | number
        name?: string | number
        timeExit?: string | number
        address?: string | number
        timeCall?: string | number
        gbr?: string | number
        reason?: string | number
        eventType?: string | number
        pult?: string
        dislocationCountByMounth?: string | number
        dislocationType?: string | number
        alertType?: string | number
        createdAt?: string | number
        comment?: string | number
        system?: string | number
        admin?: string | number
        System?: string
        Pult?: string | number
        timeDislocationStart?: string 
        // isLessTwoHours: string,
        // isNoDeparture: string 
    }

    const columns: ColumnsType<DataType> = dislocations?.headers.map((element: any, index: number) => {
        return ({
            key: element.name,
            title: element.name,
            dataIndex: element.mnemo,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
            render: (address: any) => {
                return (
                    <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                        {typeof address === 'string' ? address : ''}
                    </Tooltip>
                )
            },
        });
    }) || [];

    const dataSource: DataType[] = dislocations?.data
        ? dislocations.data.map((element: any, index: number) => {
            return {
                key: element.id,
                name: element?.name,
                timeExit: element?.timeExit && formatIsoToDate(element?.timeExit) + ` ` + formatIsoToTime(element?.timeExit),
                address: element?.address,
                timeCall: element?.timeCall && formatIsoToDate(element?.timeCall) + ` ` + formatIsoToTime(element?.timeCall),
                gbr: element?.gbr,
                reason: element?.reason,
                eventType: element?.eventType,
                pult: element?.pult,
                dislocationCountByMounth: element?.dislocationCountByMounth,
                dislocationType: element?.dislocationType,
                alertType: element?.alertType,
                createdAt: element?.createdAt,
                comment: element?.comment,
                system: element?.system,
                admin: element?.admin,
                System:  element?.system,
                Pult: element?.pult,
                timeDislocationStart: element?.timeDislocationStart && formatIsoToDate(element?.timeDislocationStart) + ` ` + formatIsoToTime(element?.timeDislocationStart),
            };
        })
        : [];

        

    const [activeTable, setActiveTable] = useState<boolean>(false)
    return <div className={s.container}>
        {openPopup && <InfoModal
            closeHandler={() => setOpenPopup(false)}
            item={clickedItem}
            arrData={arrData}
            open={openPopup}
            title={`Информация о дислокации  - ${clickedItem?.name}`}
        />}
        <div className={s.container__header}>
            <TableFiltersBlock flag='dislocation' xlsxFlag='DislocationsReport' request={requestHandler} setActiveTable={setActiveTable} />
        </div>
        <div className={s.container__table}>
            {mobile ?
                <MobileDislocation
                loading = {dislocationsLoading}
                items = {dislocations?.data}
                onItemClick = {onItemClick}/>
            :
            <Table
                size='large'
                rowClassName={s.tableRow}
                columns={columns}
                dataSource={dataSource}
                bordered
                title={defaultTitle}
                scroll={scroll}
                loading={dislocationsLoading}
                pagination={{ position: ['topLeft', 'bottomLeft'], pageSizeOptions: [25, 50, 75, 100], defaultPageSize: 25 }}
                onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: (event) => { onItemClick(record) },
                    };
                }}
            />
            }
          
        </div>
    </div>
})